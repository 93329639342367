<template>
  <el-dialog
    :custom-class="!status ? 'container success' : 'container'"
    :visible.sync="dialogVisible"
    :width="status ? '900px' : '480px'"
    :top="!status ? '15vh' : '25vh'"
    :before-close="handleClose"
  >
    <i class="close" @click="handleClose"></i>
    <div class="content" v-show="status">
      <div class="left"></div>
      <div class="right">
        <div class="top">
          <img
            src="https://web-data.zmlearn.com/image/ifVDFFB3xHAbUfVF5vPDCV/499_03.png"
          />
        </div>
        <div class="form-ctrl">
          <input
            type="text"
            placeholder="请输入您孩子的姓名"
            v-model="info.firstName"
          />
        </div>
        <div class="form-ctrl select">
          <div class="item">
            <select v-model="info.stuGrade" @change="handleSelectGrade">
              <option
                :value="item.label"
                v-for="(item, index) in gradeList"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
            <i></i>
          </div>
          <div class="item">
            <select v-model="info.weakSubject" @change="handleSelectCourse">
              <option
                :value="item.label"
                v-for="(item, index) in subjectList"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
            <i></i>
          </div>
        </div>
        <div class="form-ctrl tell">
          <div class="areaCode">
            <select :value="areaCode" @change="handleChangeGrade">
              <option
                :value="`+${item.areaCode}`"
                v-for="(item, index) in areaCodeList"
                :key="index"
                >+ {{ item.areaCode }}</option
              >
            </select>
          </div>
          <i>|</i>
          <input
            type="tel"
            placeholder="请输入手机号码"
            v-model="cus_mobile"
            :readonly="!!mobile"
            maxlength="11"
          />
        </div>
        <div class="form-ctrl btn" @click="handleSubmit">免费领取课程</div>
        <div class="part10-bottom" @click="handleAgree">
          <span class="tip" :class="agreeIcon ? 'icon' : ''"></span>
          已阅读<a
            target="_blank"
            href="https://www.zhangmen.com/service-agreement.html"
            >《用户注册协议》</a
          >和
          <a
            target="_blank"
            href="https://www.zhangmen.com/privacy-agreement.html"
            >《用户隐私政策》</a
          >
        </div>
      </div>
    </div>
    <div v-show="!status"></div>
  </el-dialog>
</template>

<script>
import codeList from "./areaCode";
import reportEvent, { getGrade } from "./../js/request";
import { completeInfo, registerRightNow } from "../js/request";
const regMobile = /^1\d{10}$/;
export default {
  data() {
    return {
      dialogVisible: false,
      gradeList: [],
      activeGradeIndex: 0,
      activeCourseIndex: 0,

      areaCode: "+86",
      agreeIcon: "",
      areaCodeList: codeList,
      subjectList: [],
      info: {
        firstName: "",
        stuGrade: "高三",
        weakSubject: "数学"
      },
      cus_mobile: "",
      status: true
    };
  },
  created() {
    getGrade().then(data => {
      const filterLbale = [
        "未入园",
        "幼儿园小班",
        "幼儿园中班",
        "幼儿园大班",
        "高四"
      ];
      this.gradeList = data.filter(g => {
        if (filterLbale.indexOf(g.label) == -1) {
          return g;
        }
      });
      this.subjectList = this.gradeList.find(
        g => g.label === this.info.stuGrade
      ).children;
    });
  },
  props: {
    mobile: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    },
    channelCode: {
      type: String,
      default: ""
    },
    channelKey: {
      type: String,
      default: ""
    }
  },
  computed: {
    courseList() {
      if (this.gradeList[this.activeGradeIndex]) {
        return this.gradeList[this.activeGradeIndex].children.filter(c => {
          if (!c.label.includes("陪练")) return c;
        });
      }
      return [];
    }
  },
  watch: {
    mobile(newVal) {
      this.cus_mobile = newVal;
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleSelectCourse(event) {
      const val = event.target.value;
      console.log(val);
      this.info.weakSubject = val;
    },
    handleSelectGrade(event) {
      const val = event.target.value;
      this.info.stuGrade = val;
      this.subjectList = this.gradeList.find(
        g => g.label === this.info.stuGrade
      ).children;
      this.info.weakSubject = this.subjectList[0].label;
    },
    handleAgree() {
      this.agreeIcon = !this.agreeIcon;
    },
    handleSubmit() {
      reportEvent("click_pcpoprec_button", {
        code: this.channelCode,
        key: this.channelKey
      });
      if (this.info.firstName.length == 0) {
        return this.$message.warning("请填写姓名");
      }
      if (!this.agreeIcon) {
        return this.$message.warning("请勾选协议/政策");
      }
      if (!this.mobile) {
        if (this.cus_mobile.length == 11 && regMobile.test(this.cus_mobile)) {
          registerRightNow({
            code: this.channelCode,
            key: this.channelKey,
            mobile:
              this.areaCode.slice(1) == 86
                ? this.cus_mobile
                : this.areaCode.slice(1) + "-" + this.cus_mobile,
            userIdStr: this.userId,
            firstName: this.info.firstName,
            weakSubject: this.info.weakSubject,
            stuGrade: this.info.stuGrade,
            sendMsg: false
          }).then(res => {
            this.status = false;
            reportEvent("enter_pcscan_pop", {
              code: this.channelCode,
              key: this.channelKey
            });
          });
        } else {
          return this.$message.warning("手机号有误");
        }
      } else {
        completeInfo({
          code: this.channelCode,
          key: this.channelKey,
          mobile:
            this.areaCode.slice(1) == 86
              ? this.mobile
              : this.areaCode.slice(1) + "-" + this.mobile,
          userIdStr: this.userId,
          firstName: this.info.firstName,
          weakSubject: this.info.weakSubject,
          stuGrade: this.info.stuGrade
        }).then(res => {
          if (res) {
            // 弹框
            this.status = false;
            reportEvent("enter_pcscan_pop", {
              code: this.channelCode,
              key: this.channelKey
            });
          }
        });
      }
    },
    handleChangeGrade(event) {
      const val = event.target.value;
      this.areaCode = val;
      return;
    },
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.status = true;
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
input,
select {
  vertical-align: middle;
  outline: 0;
  -webkit-appearance: none;
}
.container {
  position: relative;
  .close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -60px;
    right: -40px;
    background-image: url("https://web-data.zmlearn.com/image/aycFggsAu7huucRVF6cSGf/close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    .left {
      width: 421px;
      height: 100%;
      background-color: #f32836;
      background-image: url("https://web-data.zmlearn.com/image/k4FJwZ69YURqrJZL89u3e5/left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .right {
      flex: 1;
      .top {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .form-ctrl {
        width: 362px;
        height: 55px;
        background-color: #f3f3f3;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 auto;
        font-size: 0;
        margin-bottom: 15px;
        input {
          border: none;
          width: 100%;
          height: 100%;
          background-color: #f3f3f3;
          padding-left: 24px;
          font-size: 18px;
          &::placeholder {
            color: #b0b0b0;
          }
        }
      }
      .tell {
        display: flex;
        align-items: center;
        .areaCode {
          width: 102px;
          height: 100%;
          line-height: 55px;
          color: #666666;
          font-size: 18px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 13px;
            height: 8px;
            right: 10px;
            background-image: url("https://web-data.zmlearn.com/image/kMZn1GNvRvQXfCTuTjavw8/arrow.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          select {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            padding-left: 15px;
            box-sizing: border-box;
          }
        }
        input {
          width: 265px;
          padding-left: 10px;
        }
        i {
          width: 5px;
          font-size: 18px;
          line-height: 55px;
          color: #b0b0b0;
        }
      }
      .select {
        display: flex;
        justify-content: space-around;
        background-color: transparent;
        .item {
          width: 175px;
          height: 100%;
          position: relative;
          select {
            width: 100%;
            height: 100%;
            border: none;
            background-color: #f3f3f3;
            border-radius: 10px;
            padding-left: 50px;
            box-sizing: border-box;
            font-size: 18px;
          }
          i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 22px;
            width: 13px;
            height: 8px;
            background-image: url("https://web-data.zmlearn.com/image/kMZn1GNvRvQXfCTuTjavw8/arrow.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
      .btn {
        font-size: 30px;
        color: #fff;
        background-image: url("https://web-data.zmlearn.com/image/r3oGqjVKZhChKpm8KX8kwD/btn.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        line-height: 55px;
        cursor: pointer;
      }
      .part10-bottom {
        font-size: 16px;
        margin-top: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tip {
          width: 20px;
          height: 20px;
          background-image: url("https://web-data.zmlearn.com/image/ti3egvvRxA2Hi5YqUcN6MP/%E5%9C%86%E8%A7%92%E7%9F%A9%E5%BD%A2%20552.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 10px;
        }
        a {
          color: #84c1eb;
          text-decoration: none;
        }
        .icon {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: -8px;
            left: -2px;
            width: 28px;
            height: 28px;
            background-image: url("https://web-data.zmlearn.com/image/5hc2HkyZVNTXQ4L9hjNiyZ/%E5%BD%A2%E7%8A%B6%2012.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  padding: 0;
  height: 500px;
  background: #fff;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
}
/deep/ .el-dialog {
  background-color: transparent;
  box-shadow: 0 0 0 rgb(0, 0, 0);
}
</style>
<style lang="scss">
.success {
  .el-dialog__body {
    padding: 0;
    height: 672px;
    background: transparent;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    background-image: url("https://web-data.zmlearn.com/image/obLztKATRgZ6X31wa5qv58/%E8%83%8C%E6%99%AF%20(1).png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}</style
>>
