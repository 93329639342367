<template>
  <div class="aqi-act-container">
    <!-- banner -->
    <div
      class="banner"
      :style="{ width: bannerStyle.width, height: bannerStyle.height }"
    >
      <img :src="bannerSrc" alt="" ref="bannerImg" />
    </div>
    <!-- 视频区域 -->
    <div class="video-container">
      <h2 class="video-title">
        <img :src="videoTitlePic[currentVideoIndex]" alt="" />
      </h2>
      <div class="video-swiper wrap">
        <img
          :style="{ opacity: currentVideoIndex === 1 ? 1 : 0 }"
          @click="handleChangeVideo('prev')"
          class="left-btn-icon"
          src="https://web-data.zmlearn.com/image/g357NshCTpq8WHzYc9nVMH/%E5%B7%A6%E6%BB%91%E6%8C%89%E9%92%AE.png"
        />
        <el-carousel
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          :initial-index="currentVideoIndex"
          ref="caruselRef"
        >
          <el-carousel-item v-for="(item, index) in videoItems" :key="index">
            <video controls :poster="item.poster" :src="item.src"></video>
          </el-carousel-item>
        </el-carousel>
        <img
          :style="{ opacity: currentVideoIndex === 0 ? 1 : 0 }"
          @click="handleChangeVideo('next')"
          class="right-btn-icon"
          src="https://web-data.zmlearn.com/image/9rBWVq2M9SDs4EHr8DwnAS/%E5%8F%B3%E6%BB%91%E6%8C%89%E9%92%AE.png"
        />
      </div>
    </div>
    <!-- 领取心理科 -->
    <div class="psychology-class-container">
      <div class="psychology-class wrap">
        <img
          class="psychology-class-desc"
          alt=""
          src="https://web-data.zmlearn.com/image/e6uMy2UErRqjkbBV2CKi4Z/PART2.png"
        />
        <div class="psychology-class-form">
          <div class="form-input">
            <input
              type="text"
              maxlength="11"
              v-model="mobile"
              placeholder="请输入您的手机号"
            />
          </div>
          <img
            @click="handleReceive(1)"
            src="https://web-data.zmlearn.com/image/uZbHa3Bq7Mhur4WmQyELRy/%E7%BB%84%2052.png"
            class="form-button"
          />
        </div>
        <div class="agreement">
          <img
            @click="handleCheckAgreement"
            v-if="!isCheck"
            class="checkbox"
            src="https://web-data.zmlearn.com/image/fxdEo2aXywmetT5k8NERzo/-s-%E7%BB%84%208.png"
          />
          <img
            @click="handleCheckAgreement"
            v-else
            class="checkbox1"
            src="https://web-data.zmlearn.com/image/cgw3ZaD6AbbxiF1jPejCqc/-s-%E7%BB%84%2018.png"
          />
          已阅读<em @click="handleOpenAgreement(1)">《用户注册协议》</em>及<em
            @click="handleOpenAgreement(2)"
            >《隐私政策》</em
          >
        </div>
      </div>
    </div>
    <!--  更多福利 -->
    <div class="more-benefits-container">
      <img
        src="https://web-data.zmlearn.com/image/gxzw7Ki28EbJEvyCZN799e/PART3.png"
        alt=""
      />
    </div>
    <!-- 一键领取  -->
    <div class="click-collection-container">
      <div class="collection-desc wrap">
        <img
          class="desc-pic"
          src="https://web-data.zmlearn.com/image/fBjkeUzdYcT2EMtC3vXfo4/%E4%BF%A1%E6%81%AF.png"
        />
        <img
          @click="handleReceive(2)"
          class="collection-button"
          src="https://web-data.zmlearn.com/image/eCkR2V8yAf23RYRu5A7gSG/%E6%8C%89%E9%92%AE%20%E6%8B%B7%E8%B4%9D%202.png"
        />
      </div>
      <!-- <div class="bottom-collection-container">
        <div class="bottom-collection wrap">
          <div class="form-box wrap">
            <input
              type="text"
              maxlength="11"
              v-model="mobile"
              placeholder="请输入您的手机号"
            />
            <button @click="handleReceive(2)">立即免费领取</button>
            <span
              >点击即表示同意
              <br />
              <em>《用户注册协议》</em>
              和
              <em>《隐私政策》</em>
            </span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 底部 -->
    <div class="footer-wrapper">
      <div class="reservation"></div>
      <div class="navigation-wrapper ">
        <div class="navigation-main">
          <div class="hotline-container navigation-col">
            <div class="navigation-title">客服热线</div>
            <div class="hotline-phone-wrapper">
              <div class="hotline-icon"></div>
              <div class="hotline-phonenum">1010-8868</div>
            </div>
            <div class="hotline-datatime">周一至周日： 9:00-22:00</div>
            <!-- <div class="hotline-btn">在线咨询客服</div> -->
          </div>
          <div class="about-container navigation-col">
            <div class="navigation-title">关于我们</div>
            <div class="about-main">
              <a
                class="navigation-item"
                href="https://www.zhangmen.com/about-us/comprehend-us"
                target="_blank"
                >关于掌门教育</a
              >
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/about-us/index"
                target="_blank"
              >
                加入掌门
              </div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/about-us/contact-us"
                target="_blank"
              >
                联系我们
              </div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/coverage"
                target="_blank"
              >
                媒体报道
              </div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/price"
                target="_blank"
              >
                价格及退费公示
              </div>
            </div>
          </div>
          <div class="service-container navigation-col">
            <div class="navigation-title">服务和支持</div>
            <div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/client-download/"
                target="_blank"
              >
                客户端下载&APP下载
              </div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/feedback"
                target="_blank"
              >
                意见反馈
              </div>
              <div
                class="navigation-item"
                href="https://www.zhangmen.com/responsibility"
                target="_blank"
              >
                社会责任
              </div>
            </div>
          </div>
          <div class="follow-container navigation-col">
            <div class="navigation-title">关注我们</div>
            <img
              class="qrcode-img"
              src="https://web-data.zmlearn.com/image/3X5KLqetwtwuwV1aEUe6XB/qrcode.png"
              alt=""
            />
            <div class="qrcode-msg">微信扫描二维码</div>
            <div class="qrcode-msg">关注掌门公众号</div>
          </div>
        </div>
      </div>
      <div class="copyright-wrapper">
        <div class="copyright-main">
          <img
            src="https://web-data.zmlearn.com/image/ueYLp9eG13VwyTuT4vhj8P/copyright.png"
            class="copyright-img"
          />
          <div>
            <div class="copyright-text-wrapper">
              <p>
                <span>Copyright 上海掌小门教育科技有限公司</span
                ><a href="http://www.beian.miit.gov.cn/" target="_blank"
                  >沪ICP备16015460-1号</a
                >
                <a href="http://www.beian.miit.gov.cn/" target="_blank"
                  >ICP认证：沪B2-20180817</a
                >
                <a href="http://www.shjbzx.cn/" target="_blank"
                  >上海互联网举报中心</a
                >
                <a
                  href="http://report.12377.cn:13225/toreportinputNormal_anis.do"
                  target="_blank"
                  >网络有害信息举报</a
                >
              </p>
              <p>
                <span
                  >客服电话: 10108868 商务合作：bd@zhangmen.com
                  地址:上海市虹口区四平路96号金融街海伦中心</span
                >
                <a
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902002669"
                  target="_blank"
                  >沪公网安备 31010902002669号</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RegisterDialog
      ref="registerDialog"
      :channelCode="code"
      :channelKey="key"
      :mobile="mobile"
      :userId="userIdStr"
    />
  </div>
</template>

<script>
import reportEvent, { delayRegister } from "./js/request";
const regMobile = /^1\d{10}$/;
import RegisterDialog from "./dialog/index";
export default {
  components: {
    RegisterDialog
  },
  data() {
    return {
      bannerSrc:
        "https://web-data.zmlearn.com/image/aWGihujwCnV4zChtx1ykN8/%E5%B0%8F%E8%88%8D%E5%BE%97PC%E7%AB%AF%E9%A1%B5%E9%9D%A2111111.png",
      bannerStyle: {},
      videoTitlePic: [
        "https://web-data.zmlearn.com/image/vvzZdm79AhqjsyMdw3pz7x/11111.png",
        "https://web-data.zmlearn.com/image/uXSwjvxKCGqYVepfizpUwU/222222222.png"
      ],
      videoItems: [
        {
          poster:
            "https://web-data.zmlearn.com/image/65fSRE6MYqB4PykrHnTnkv/%E7%AC%AC%E4%B8%80%E9%9B%861.jpg",
          src:
            "https://web-data.zmlearn.com/media/nfTz2txU8qFZo4MwebAL21/%E7%AC%AC%E4%B8%80%E9%9B%86%E6%97%A0%E5%B9%BF%E5%91%8A%E6%96%87%E5%AD%97172M.mp4"
        },
        {
          poster:
            "https://web-data.zmlearn.com/image/iXAFo5XeTPEKBfXeVHrzeV/%E7%AC%AC%E4%BA%8C%E9%9B%86.jpg",
          src:
            "https://web-data.zmlearn.com/media/wjQkGWPtZzgPtELLPYhUqg/%E7%AC%AC%E4%BA%8C%E9%9B%86%E6%97%A0%E5%B9%BF%E5%91%8A%E6%96%87%E5%AD%97172M.mp4"
        }
      ],
      currentVideoIndex: 0,
      mobile: "",
      code: "",
      key: "",
      isCheck: false,
      userIdStr: ""
    };
  },
  created() {},
  mounted() {
    //下架
    if (process.env.NODE_ENV === "production") {
      this.$router.replace({
        path: "/404"
      });
      return;
    }
    this.code = this.$route.query.code || "26505";
    this.key = this.$route.query.key || "71ebe918a723848c";
    reportEvent("enter_aqypc_page", {
      code: this.code,
      key: this.key
    });
    this.setBannerSize();
    window.addEventListener("resize", this.setBannerSize);
  },
  methods: {
    //设置banner的尺寸
    setBannerSize() {
      const w =
        document.documentElement.clientWidth || document.body.clientWidth;
      const h =
        document.documentElement.clientHeight || document.body.clientHeight;
      const bannerStyle = {
        width: 0,
        height: 0
      };
      const bannerImgSrc = this.bannerSrc;
      const image = new Image();
      image.src = bannerImgSrc;
      image.onload = () => {
        let scale = w / image.width;
        bannerStyle.width = w + "px";
        bannerStyle.height = image.height * scale + "px";
        this.bannerStyle = bannerStyle;
      };
    },
    //切换视频
    handleChangeVideo(type) {
      if (type === "prev") {
        if (this.currentVideoIndex === 0) return;
        this.$refs.caruselRef.setActiveItem(--this.currentVideoIndex);
      } else {
        if (this.currentVideoIndex === 1) return;
        this.$refs.caruselRef.setActiveItem(++this.currentVideoIndex);
      }
    },
    handleReceive(type) {
      if (type === 1) {
        // 心理课
        reportEvent("click_pcrecxlk_button", {
          code: this.code,
          key: this.key
        });
        if (this.mobile === "") {
          return this.$message.error("请输入手机号码");
        } else if (!regMobile.test(this.mobile)) {
          return this.$message.error("请输入正确手机号码");
        }
        if (!this.isCheck) {
          return this.$message.error("请勾选用户注册协议及隐私政策");
        }
        this.doDelayRegister();
        return;
      }
      reportEvent("click_pcbuttomrec_button", {
        code: this.code,
        key: this.key
      });
      this.mobile = "";
      // 弹窗窗口
      this.$refs.registerDialog.open();
    },
    // 点击协议checkbox
    handleCheckAgreement() {
      this.isCheck = !this.isCheck;
    },
    // 打开协议
    handleOpenAgreement(type) {
      if (type === 1) {
        // 用户协议
        window.open("https://www.zhangmen.com/service-agreement.html");
      } else {
        // 隐私政策
        window.open("https://www.zhangmen.com/privacy-agreement.html");
      }
    },
    doDelayRegister() {
      const params = {
        code: this.code,
        key: this.key,
        mobile: this.mobile
      };
      delayRegister(params)
        .then(res => {
          this.$message({
            message: "恭喜你，领取成功！",
            type: "success"
          });
          // 弹窗窗口
          setTimeout(() => {
            this.userIdStr = res;
            this.$refs.registerDialog.open();
            reportEvent("enter_pcwsxx_pop", {
              code: this.code,
              key: this.key
            });
          }, 1000);
        })
        .catch(console.log);
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.aqi-act-container {
  min-width: 1100px;
  background: #fff;
}
.banner {
  width: 100%;
  // height: 1063px;
  // background: url("https://web-data.zmlearn.com/image/ccGeTz5S1wAyDC1dW34Xeb/%E5%B0%8F%E8%88%8D%E5%BE%97PC%E7%AB%AF%E9%A1%B5%E9%9D%A2111.png");
  // background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
.wrap {
  width: 1100px;
  margin: 0 auto;
}
.video-container {
  width: 100%;
  height: 656px;
  .video-title {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 46px;
    img {
      height: 42px;
      width: auto;
    }
  }
  .video-swiper {
    height: 100%;
    height: 508px;
    display: flex;
    align-items: center;
  }
  .left-btn-icon {
    width: 44px;
    height: 44px;
    margin-right: 108px;
    cursor: pointer;
  }
  .right-btn-icon {
    width: 44px;
    height: 44px;
    margin-left: 108px;
    cursor: pointer;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.psychology-class-container {
  width: 100%;
  height: 896px;
  background: #fff6f5;
  .psychology-class {
    height: 100%;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .psychology-class-desc {
      width: 100%;
    }
  }
  .psychology-class-form {
    width: 100%;
    height: 234px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 34px;
    .form-input {
      background: url("https://web-data.zmlearn.com/image/nGe7jjxGQk9MvLTTEhsvM3/%E8%BE%93%E5%85%A5%E6%89%8B%E6%9C%BA%E5%8F%B7.png");
      width: 498px;
      height: 115px;
      padding: 0 30px;
      padding-bottom: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: 100% 100%;
      input {
        width: 100%;
        height: 40px;
        color: #333;
        font-size: 25px;
        border: none;
        outline: none;
      }
    }
    .form-button {
      width: 498px;
      height: 115px;
      cursor: pointer;
    }
  }
}
.more-benefits-container {
  width: 100%;
  height: 803px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 732px;
    width: auto;
  }
}
.click-collection-container {
  width: 100%;
  height: 954px;
  background: #fff6f5;
  position: relative;
  .collection-desc {
    height: 830px;
    padding-top: 55px;
    text-align: center;
    .desc-pic {
      width: 100%;
      height: 586px;
      margin-bottom: 20px;
    }
    .collection-button {
      width: 488px;
      height: 110px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

  .bottom-collection-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background: rgba(#000, 0.6);
    .bottom-collection {
      width: 1100px;
      height: 138px;
      background: url("https://web-data.zmlearn.com/image/6ZtbLH7vMQSfUSJSzYHz9x/%E5%90%B8%E5%BA%95.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
    }
    .form-box {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      input {
        width: 180px;
        height: 36px;
        background: #fff;
        color: #000;
        border-radius: 18px;
        border: none;
        margin-right: 12px;
        outline: none;
        padding: 0 26px;
      }
      button {
        width: 182px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        font-size: 18px;
        color: #fff;
        border-radius: 18px;
        background: #f32735;
        cursor: pointer;
        margin-right: 12px;
      }
      span {
        font-size: 12px;
        color: #fff;
        width: 200px;
        text-align: center;
        em {
          cursor: pointer;
        }
      }
    }
  }
}
.agreement {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #4f4f4f;
  font-weight: bold;
  em {
    color: #fe3a29;
    cursor: pointer;
  }
  .checkbox {
    width: 14px;
    height: 14px;
    margin-right: 16px;
    cursor: pointer;
  }
  .checkbox1 {
    width: 19px;
    height: 20px;
    margin-right: 12px;
  }
}
.footer-wrapper {
  .reservation {
    // width: 1920px;
    height: 72px;
    background: url("https://web-data.zmlearn.com/image/kgcRejs8uYFsjcfixP5Rvy/%E5%9B%BE%E5%B1%82%20968.png")
      center no-repeat;
    background-size: cover;
  }
  .navigation-wrapper {
    background: #333333;
    height: 249px;
    width: 100%;
    .navigation-title {
      height: 82px;
      line-height: 82px;
      color: #ffffff;
      font-size: 16px;
    }
    .navigation-item {
      color: #999999;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
    }
    .navigation-main {
      display: flex;
      margin: 0 auto;
      height: 249px;
      width: 1100px;
      .navigation-col:first-child {
        margin-right: 70px;
      }
      .hotline-container {
        // background: red;
        flex: 1;
        // background:orange;
        .hotline-phone-wrapper {
          display: flex;
          height: 26px;
          line-height: 26px;
          .hotline-icon {
            margin-right: 7px;
            width: 26px;
            height: 26px;
            background: url("https://web-data.zmlearn.com/image/tUhNEShCuzEwvGs2ZTPbtE/zuoji.png")
              no-repeat center / 100% 100%;
          }
          .hotline-phonenum {
            font-size: 30px;
            color: #fff;
          }
        }
        .hotline-datatime {
          height: 36px;
          line-height: 36px;
          color: #999;
          font-size: 14px;
        }
        .hotline-btn {
          margin-top: 10px;
          text-align: center;
          color: #ffffff;
          height: 40px;
          width: 171px;
          line-height: 40px;
          font-size: 16px;
          border-radius: 20px;
          background-color: #f81e27;
        }
      }
      .about-container {
        flex: 1;
        //  background:red;
      }
      .service-container {
        flex: 1;
        //  background:green;
      }
      .follow-container {
        //  background:orange;
        flex: 1;
        .qrcode-img {
          width: 94px;
          height: 94px;
          margin-bottom: 10px;
        }
        .qrcode-msg {
          font-size: 12px;
          height: 15px;
          color: #999999;
        }
      }
    }
  }
  .copyright-wrapper {
    height: 68px;
    width: 100%;
    background-color: #292929;
    .copyright-main {
      height: 32px;
      display: flex;
      margin: 0 auto;
      width: 1100px;
      .copyright-img {
        margin: 23px 9px;
        line-height: 22px;
        width: 246px;
        height: 22px;
      }
      .copyright-text-wrapper {
        margin: 14px 0;
        height: 45px;
        .copyright-text {
          color: #777777;
          font-size: 12px;
        }
      }
    }
  }
  a {
    color: #666;
  }
}
</style>
<style lang="scss">
.aqi-act-container .el-carousel {
  flex: 1;
  height: 100%;
}
.aqi-act-container .el-carousel__container {
  height: 100%;
}
</style>
