const codeList = [
  {
    enShortName: "BY",
    areaCode: 375,
    index: 1,
    cnName: "白俄罗斯",
    enName: "Belarus",
    cnShortName: "B"
  },
  {
    enShortName: "TM",
    areaCode: 993,
    index: 2,
    cnName: "土库曼",
    enName: "Turkmenistan",
    cnShortName: "T"
  },
  {
    enShortName: "IN",
    areaCode: 91,
    index: 3,
    cnName: "印度",
    enName: "India",
    cnShortName: "Y"
  },
  {
    enShortName: "MV",
    areaCode: 960,
    index: 4,
    cnName: "马尔代夫",
    enName: "Maldives",
    cnShortName: "M"
  },
  {
    enShortName: "LT",
    areaCode: 370,
    index: 5,
    cnName: "立陶宛",
    enName: "Lithuania",
    cnShortName: "L"
  },
  {
    enShortName: "AD",
    areaCode: 376,
    index: 6,
    cnName: "安道尔共和国",
    enName: "Andorra",
    cnShortName: "A"
  },
  {
    enShortName: "CA",
    areaCode: 1,
    index: 7,
    cnName: "加拿大",
    enName: "Canada",
    cnShortName: "J"
  },
  {
    enShortName: "KE",
    areaCode: 254,
    index: 8,
    cnName: "肯尼亚",
    enName: "Kenya",
    cnShortName: "K"
  },
  {
    enShortName: "US",
    areaCode: 1,
    index: 9,
    cnName: "美国",
    enName: "United States",
    cnShortName: "M"
  },
  {
    enShortName: "TJ",
    areaCode: 992,
    index: 10,
    cnName: "塔吉克",
    enName: "Tajikistan",
    cnShortName: "T"
  },
  {
    enShortName: "BR",
    areaCode: 55,
    index: 11,
    cnName: "巴西",
    enName: "Brazil",
    cnShortName: "B"
  },
  {
    enShortName: "CU",
    areaCode: 53,
    index: 12,
    cnName: "古巴",
    enName: "Cuba",
    cnShortName: "G"
  },
  {
    enShortName: "MNE",
    areaCode: 382,
    index: 13,
    cnName: "黑山共和国",
    enName: "Montenegro",
    cnShortName: "H"
  },
  {
    enShortName: "PK",
    areaCode: 92,
    index: 14,
    cnName: "巴基斯坦",
    enName: "Pakistan",
    cnShortName: "B"
  },
  {
    enShortName: "UA",
    areaCode: 380,
    index: 15,
    cnName: "乌克兰",
    enName: "Ukraine",
    cnShortName: "W"
  },
  {
    enShortName: "MKD",
    areaCode: 389,
    index: 16,
    cnName: "马其顿",
    enName: "The Republic of Macedonia",
    cnShortName: "M"
  },
  {
    enShortName: "BS",
    areaCode: 1242,
    index: 17,
    cnName: "巴哈马",
    enName: "Bahamas",
    cnShortName: "B"
  },
  {
    enShortName: "BA",
    areaCode: 387,
    index: 18,
    cnName: "波斯尼亚和黑塞哥维那",
    enName: "Bosnia and Herzegovina",
    cnShortName: "B"
  },
  {
    enShortName: "FK",
    areaCode: 500,
    index: 19,
    cnName: "福克兰群岛",
    enName: "Falkland Islands (Malvinas)",
    cnShortName: "F"
  },
  {
    enShortName: "JO",
    areaCode: 962,
    index: 20,
    cnName: "约旦",
    enName: "Jordan",
    cnShortName: "Y"
  },
  {
    enShortName: "MS",
    areaCode: 1664,
    index: 21,
    cnName: "蒙特塞拉特",
    enName: "Montserrat",
    cnShortName: "M"
  },
  {
    enShortName: "PW",
    areaCode: 680,
    index: 22,
    cnName: "帛琉",
    enName: "Palau",
    cnShortName: "B"
  },
  {
    enShortName: "PH",
    areaCode: 63,
    index: 23,
    cnName: "菲律宾共和国",
    enName: "Philippines",
    cnShortName: "F"
  },
  {
    enShortName: "TH",
    areaCode: 66,
    index: 24,
    cnName: "泰国",
    enName: "Thailand",
    cnShortName: "T"
  },
  {
    enShortName: "SRB",
    areaCode: 381,
    index: 25,
    cnName: "塞尔维亚共和国",
    enName: "Serbia",
    cnShortName: "S"
  },
  {
    enShortName: "YU",
    areaCode: 381,
    index: 26,
    cnName: "南斯拉夫",
    enName: "Yugoslavia",
    cnShortName: "N"
  },
  {
    enShortName: "AR",
    areaCode: 54,
    index: 27,
    cnName: "阿根廷",
    enName: "Argentina",
    cnShortName: "A"
  },
  {
    enShortName: "DK",
    areaCode: 45,
    index: 28,
    cnName: "丹麦",
    enName: "Denmark",
    cnShortName: "D"
  },
  {
    enShortName: "GL",
    areaCode: 45,
    index: 29,
    cnName: "格陵兰",
    enName: "Greenland",
    cnShortName: "G"
  },
  {
    enShortName: "SI",
    areaCode: 386,
    index: 30,
    cnName: "斯洛文尼亚",
    enName: "Slovenia",
    cnShortName: "S"
  },
  {
    enShortName: "CO",
    areaCode: 57,
    index: 31,
    cnName: "哥伦比亚",
    enName: "Colombia",
    cnShortName: "G"
  },
  {
    enShortName: "BH",
    areaCode: 973,
    index: 32,
    cnName: "巴林",
    enName: "Bahrain",
    cnShortName: "B"
  },
  {
    enShortName: "IL",
    areaCode: 972,
    index: 33,
    cnName: "以色列",
    enName: "Israel",
    cnShortName: "Y"
  },
  {
    enShortName: "PY",
    areaCode: 595,
    index: 34,
    cnName: "巴拉圭",
    enName: "Paraguay",
    cnShortName: "B"
  },
  {
    enShortName: "VE",
    areaCode: 58,
    index: 35,
    cnName: "委内瑞拉",
    enName: "Venezuela",
    cnShortName: "W"
  },
  {
    enShortName: "GI",
    areaCode: 350,
    index: 36,
    cnName: "直布罗陀",
    enName: "Gibraltar",
    cnShortName: "Z"
  },
  {
    enShortName: "ZM",
    areaCode: 260,
    index: 37,
    cnName: "赞比亚",
    enName: "Zambia",
    cnShortName: "Z"
  },
  {
    enShortName: "AL",
    areaCode: 355,
    index: 38,
    cnName: "阿尔巴尼亚",
    enName: "Albania",
    cnShortName: "A"
  },
  {
    enShortName: "BZ",
    areaCode: 501,
    index: 39,
    cnName: "伯利兹城",
    enName: "Belize",
    cnShortName: "B"
  },
  {
    enShortName: "BM",
    areaCode: 1441,
    index: 40,
    cnName: "百慕大",
    enName: "Bermuda",
    cnShortName: "B"
  },
  {
    enShortName: "BN",
    areaCode: 673,
    index: 41,
    cnName: "文莱达鲁萨兰国",
    enName: "Brunei Darussalam",
    cnShortName: "W"
  },
  {
    enShortName: "BF",
    areaCode: 226,
    index: 42,
    cnName: "布基纳法索",
    enName: "Burkina Faso",
    cnShortName: "B"
  },
  {
    enShortName: "GQ",
    areaCode: 240,
    index: 43,
    cnName: "赤道几内亚",
    enName: "Equatorial Guinea",
    cnShortName: "C"
  },
  {
    enShortName: "ET",
    areaCode: 251,
    index: 44,
    cnName: "埃塞俄比亚",
    enName: "Ethiopia",
    cnShortName: "A"
  },
  {
    enShortName: "FO",
    areaCode: 298,
    index: 45,
    cnName: "法罗群岛",
    enName: "Faroe Islands",
    cnShortName: "F"
  },
  {
    enShortName: "HN",
    areaCode: 504,
    index: 46,
    cnName: "洪都拉斯",
    enName: "Honduras",
    cnShortName: "H"
  },
  {
    enShortName: "KZ",
    areaCode: 7,
    index: 47,
    cnName: "哈萨克",
    enName: "Kazakhstan",
    cnShortName: "H"
  },
  {
    enShortName: "KG",
    areaCode: 996,
    index: 48,
    cnName: "吉尔吉斯",
    enName: "Kyrgyzstan",
    cnShortName: "J"
  },
  {
    enShortName: "MT",
    areaCode: 356,
    index: 49,
    cnName: "马尔他",
    enName: "Malta",
    cnShortName: "M"
  },
  {
    enShortName: "MX",
    areaCode: 52,
    index: 50,
    cnName: "墨西哥",
    enName: "Mexico",
    cnShortName: "M"
  },
  {
    enShortName: "PL",
    areaCode: 48,
    index: 51,
    cnName: "波兰",
    enName: "Poland",
    cnShortName: "B"
  },
  {
    enShortName: "RU",
    areaCode: 7,
    index: 52,
    cnName: "俄罗斯联邦",
    enName: "Russian Federation",
    cnShortName: "É"
  },
  {
    enShortName: "SB",
    areaCode: 677,
    index: 53,
    cnName: "索罗门群岛",
    enName: "Solomon Islands",
    cnShortName: "S"
  },
  {
    enShortName: "ZA",
    areaCode: 27,
    index: 54,
    cnName: "南非",
    enName: "South Africa",
    cnShortName: "N"
  },
  {
    enShortName: "LK",
    areaCode: 94,
    index: 55,
    cnName: "斯里兰卡",
    enName: "Sri Lanka",
    cnShortName: "S"
  },
  {
    enShortName: "ABH",
    areaCode: 7,
    index: 56,
    cnName: "阿布哈兹",
    enName: "the Republic of Abkhazia",
    cnShortName: "A"
  },
  {
    enShortName: "SO",
    areaCode: 7,
    index: 57,
    cnName: "南奥赛梯",
    enName: "the Republic of South Ossetia",
    cnShortName: "N"
  },
  {
    enShortName: "GA",
    areaCode: 241,
    index: 58,
    cnName: "加蓬",
    enName: "Gabon",
    cnShortName: "J"
  },
  {
    enShortName: "ML",
    areaCode: 223,
    index: 59,
    cnName: "马里",
    enName: "Mali",
    cnShortName: "M"
  },
  {
    enShortName: "OM",
    areaCode: 968,
    index: 60,
    cnName: "阿曼",
    enName: "Oman",
    cnShortName: "A"
  },
  {
    enShortName: "SA",
    areaCode: 966,
    index: 61,
    cnName: "沙特阿拉伯",
    enName: "Saudi Arabia",
    cnShortName: "S"
  },
  {
    enShortName: "ID",
    areaCode: 62,
    index: 62,
    cnName: "印度尼西亚",
    enName: "Indonesia",
    cnShortName: "Y"
  },
  {
    enShortName: "PE",
    areaCode: 51,
    index: 63,
    cnName: "秘鲁",
    enName: "Peru",
    cnShortName: "M"
  },
  {
    enShortName: "RW",
    areaCode: 250,
    index: 64,
    cnName: "卢旺达",
    enName: "Rwanda",
    cnShortName: "L"
  },
  {
    enShortName: "PR",
    areaCode: 1787,
    index: 65,
    cnName: "波多黎各",
    enName: "Puerto Rico",
    cnShortName: "B"
  },
  {
    enShortName: "SC",
    areaCode: 248,
    index: 66,
    cnName: "塞舌尔",
    enName: "Seychelles",
    cnShortName: "S"
  },
  {
    enShortName: "KH",
    areaCode: 855,
    index: 67,
    cnName: "柬埔寨",
    enName: "Cambodia",
    cnShortName: "J"
  },
  {
    enShortName: "AE",
    areaCode: 971,
    index: 68,
    cnName: "阿拉伯联合酋长国",
    enName: "United Arab Emirates",
    cnShortName: "A"
  },
  {
    enShortName: "CF",
    areaCode: 236,
    index: 69,
    cnName: "中非共和国",
    enName: "Central African Republic",
    cnShortName: "Z"
  },
  {
    enShortName: "CK",
    areaCode: 682,
    index: 70,
    cnName: "库克群岛",
    enName: "Cook Islands",
    cnShortName: "K"
  },
  {
    enShortName: "GM",
    areaCode: 220,
    index: 71,
    cnName: " 冈比亚",
    enName: "Gambia",
    cnShortName: " "
  },
  {
    enShortName: "LB",
    areaCode: 961,
    index: 72,
    cnName: "黎巴嫩",
    enName: "Lebanon",
    cnShortName: "L"
  },
  {
    enShortName: "LS",
    areaCode: 266,
    index: 73,
    cnName: "莱索托",
    enName: "Lesotho",
    cnShortName: "L"
  },
  {
    enShortName: "LU",
    areaCode: 352,
    index: 74,
    cnName: "卢森堡",
    enName: "Luxembourg",
    cnShortName: "L"
  },
  {
    enShortName: "PT",
    areaCode: 351,
    index: 75,
    cnName: "葡萄牙",
    enName: "Portugal",
    cnShortName: "P"
  },
  {
    enShortName: "ZW",
    areaCode: 263,
    index: 76,
    cnName: "津巴布韦",
    enName: "Zimbabwe",
    cnShortName: "J"
  },
  {
    enShortName: "HK",
    areaCode: 852,
    index: 77,
    cnName: "香港（中国）",
    enName: "Hong Kong",
    cnShortName: "X"
  },
  {
    enShortName: "AM",
    areaCode: 374,
    index: 78,
    cnName: "亚美尼亚",
    enName: "Armenia",
    cnShortName: "Y"
  },
  {
    enShortName: "TD",
    areaCode: 235,
    index: 79,
    cnName: "乍得",
    enName: "Chad",
    cnShortName: "Z"
  },
  {
    enShortName: "IS",
    areaCode: 354,
    index: 80,
    cnName: "冰岛",
    enName: "Iceland",
    cnShortName: "B"
  },
  {
    enShortName: "LI",
    areaCode: 423,
    index: 81,
    cnName: "列支敦士登",
    enName: "Liechtenstein",
    cnShortName: "L"
  },
  {
    enShortName: "MG",
    areaCode: 261,
    index: 82,
    cnName: "马达加斯加",
    enName: "Madagascar",
    cnShortName: "M"
  },
  {
    enShortName: "NI",
    areaCode: 505,
    index: 83,
    cnName: "尼加拉瓜",
    enName: "Nicaragua",
    cnShortName: "N"
  },
  {
    enShortName: "PS",
    areaCode: 970,
    index: 84,
    cnName: "巴勒斯坦",
    enName: "Palestine",
    cnShortName: "B"
  },
  {
    enShortName: "PG",
    areaCode: 675,
    index: 85,
    cnName: "巴布亚新几内亚",
    enName: "Papua New Guinea",
    cnShortName: "B"
  },
  {
    enShortName: "SZ",
    areaCode: 268,
    index: 86,
    cnName: "斯威士兰",
    enName: "Swaziland",
    cnShortName: "S"
  },
  {
    enShortName: "SG",
    areaCode: 65,
    index: 87,
    cnName: "新加坡",
    enName: "Singapore",
    cnShortName: "X"
  },
  {
    enShortName: "QA",
    areaCode: 974,
    index: 88,
    cnName: "卡塔尔",
    enName: "Qatar",
    cnShortName: "K"
  },
  {
    enShortName: "TG",
    areaCode: 228,
    index: 89,
    cnName: "多哥",
    enName: "Togo",
    cnShortName: "D"
  },
  {
    enShortName: "CR",
    areaCode: 506,
    index: 90,
    cnName: "哥斯达黎加",
    enName: "Costa Rica",
    cnShortName: "G"
  },
  {
    enShortName: "YE",
    areaCode: 967,
    index: 91,
    cnName: "也门",
    enName: "Yemen",
    cnShortName: "Y"
  },
  {
    enShortName: "UK",
    areaCode: 44,
    index: 92,
    cnName: "英国",
    enName: "United Kingdom",
    cnShortName: "Y"
  },
  {
    enShortName: "BJ",
    areaCode: 44,
    index: 93,
    cnName: "泽西岛",
    enName: "Bailiwick of Jersey",
    cnShortName: "Z"
  },
  {
    enShortName: "DM",
    areaCode: 1767,
    index: 94,
    cnName: "多米尼克国",
    enName: "Dominica",
    cnShortName: "D"
  },
  {
    enShortName: "AN",
    areaCode: 599,
    index: 95,
    cnName: "荷兰安的列斯群岛",
    enName: "Netherlands Antilles",
    cnShortName: "H"
  },
  {
    enShortName: "TW",
    areaCode: 886,
    index: 96,
    cnName: "台湾（中国）",
    enName: "Taiwan",
    cnShortName: "T"
  },
  {
    enShortName: "AI",
    areaCode: 1264,
    index: 97,
    cnName: "安圭拉岛",
    enName: "Anguilla",
    cnShortName: "A"
  },
  {
    enShortName: "AG",
    areaCode: 1268,
    index: 98,
    cnName: "安提瓜和巴布达",
    enName: "Antigua and Barbuda",
    cnShortName: "A"
  },
  {
    enShortName: "AW",
    areaCode: 297,
    index: 99,
    cnName: "阿鲁巴",
    enName: "Aruba",
    cnShortName: "A"
  },
  {
    enShortName: "BB",
    areaCode: 1246,
    index: 100,
    cnName: "巴巴多斯",
    enName: "Barbados",
    cnShortName: "B"
  },
  {
    enShortName: "KY",
    areaCode: 1345,
    index: 101,
    cnName: "开曼群岛",
    enName: "Cayman Islands",
    cnShortName: "K"
  },
  {
    enShortName: "EG",
    areaCode: 20,
    index: 102,
    cnName: "埃及",
    enName: "Egypt",
    cnShortName: "A"
  },
  {
    enShortName: "SV",
    areaCode: 503,
    index: 103,
    cnName: "萨尔瓦多",
    enName: "El Salvador",
    cnShortName: "S"
  },
  {
    enShortName: "GD",
    areaCode: 1473,
    index: 104,
    cnName: "格林纳达",
    enName: "Grenada",
    cnShortName: "G"
  },
  {
    enShortName: "JM",
    areaCode: 1876,
    index: 105,
    cnName: "牙买加",
    enName: "Jamaica",
    cnShortName: "Y"
  },
  {
    enShortName: "MW",
    areaCode: 265,
    index: 106,
    cnName: "马拉维",
    enName: "Malawi",
    cnShortName: "M"
  },
  {
    enShortName: "MC",
    areaCode: 377,
    index: 107,
    cnName: "摩纳哥",
    enName: "Monaco",
    cnShortName: "M"
  },
  {
    enShortName: "MN",
    areaCode: 976,
    index: 108,
    cnName: "外蒙古",
    enName: "Mongolia",
    cnShortName: "W"
  },
  {
    enShortName: "MZ",
    areaCode: 258,
    index: 109,
    cnName: "莫桑比克",
    enName: "Mozambique",
    cnShortName: "M"
  },
  {
    enShortName: "NR",
    areaCode: 674,
    index: 110,
    cnName: "瑙鲁",
    enName: "Nauru",
    cnShortName: "N"
  },
  {
    enShortName: "NG",
    areaCode: 234,
    index: 111,
    cnName: "尼日利亚",
    enName: "Nigeria",
    cnShortName: "N"
  },
  {
    enShortName: "PA",
    areaCode: 507,
    index: 112,
    cnName: "巴拿马",
    enName: "Panama",
    cnShortName: "B"
  },
  {
    enShortName: "TT",
    areaCode: 1868,
    index: 113,
    cnName: "特立尼达和多巴哥",
    enName: "Trinidad and Tobago",
    cnShortName: "T"
  },
  {
    enShortName: "TC",
    areaCode: 1809,
    index: 114,
    cnName: "土克斯及开科斯群岛",
    enName: "Turks and Caicos Islands",
    cnShortName: "T"
  },
  {
    enShortName: "VI",
    areaCode: 1340,
    index: 115,
    cnName: "维尔京群岛(美国)",
    enName: "Virgin Islands (U.S.)",
    cnShortName: "W"
  },
  {
    enShortName: "KNA",
    areaCode: 1869,
    index: 116,
    cnName: "圣基茨和尼维斯",
    enName: "The Federation of Saint Kitts and Nevis",
    cnShortName: "S"
  },
  {
    enShortName: "VCT",
    areaCode: 1784,
    index: 117,
    cnName: "圣文森特和格林纳丁斯",
    enName: "Saint Vincent and the Grenadines",
    cnShortName: "S"
  },
  {
    enShortName: "MP",
    areaCode: 1670,
    index: 118,
    cnName: "北马里亚纳群岛",
    enName: "Northern Mariana Islands",
    cnShortName: "B"
  },
  {
    enShortName: "AU",
    areaCode: 61,
    index: 119,
    cnName: "澳大利亚",
    enName: "Australia",
    cnShortName: "A"
  },
  {
    enShortName: "CX",
    areaCode: 61,
    index: 120,
    cnName: "圣延岛",
    enName: "Christmas Island",
    cnShortName: "S"
  },
  {
    enShortName: "CC",
    areaCode: 61,
    index: 121,
    cnName: "科科斯群岛",
    enName: "Cocos (Keeling) Islands",
    cnShortName: "K"
  },
  {
    enShortName: "DE",
    areaCode: 49,
    index: 122,
    cnName: "德国",
    enName: "Germany",
    cnShortName: "D"
  },
  {
    enShortName: "LV",
    areaCode: 371,
    index: 123,
    cnName: "拉脱维亚",
    enName: "Latvia",
    cnShortName: "L"
  },
  {
    enShortName: "SK",
    areaCode: 421,
    index: 124,
    cnName: "斯洛伐克（斯洛伐克人的共和国）",
    enName: "Slovakia (Slovak Republic)",
    cnShortName: "S"
  },
  {
    enShortName: "GY",
    areaCode: 592,
    index: 125,
    cnName: "圭亚那",
    enName: "Guyana",
    cnShortName: "G"
  },
  {
    enShortName: "LY",
    areaCode: 218,
    index: 126,
    cnName: "利比亚",
    enName: "Libyan Arab Jamahiriya",
    cnShortName: "L"
  },
  {
    enShortName: "KP",
    areaCode: 850,
    index: 127,
    cnName: "朝鲜",
    enName: "North Korea",
    cnShortName: "Z"
  },
  {
    enShortName: "NO",
    areaCode: 47,
    index: 128,
    cnName: "挪威",
    enName: "Norway",
    cnShortName: "N"
  },
  {
    enShortName: "KR",
    areaCode: 82,
    index: 129,
    cnName: "韩国",
    enName: "South Korea",
    cnShortName: "H"
  },
  {
    enShortName: "UY",
    areaCode: 598,
    index: 130,
    cnName: "乌拉圭",
    enName: "Uruguay",
    cnShortName: "W"
  },
  {
    enShortName: "LAO",
    areaCode: 856,
    index: 131,
    cnName: "老挝",
    enName: "Lao People's Democratic Republic",
    cnShortName: "L"
  },
  {
    enShortName: "CL",
    areaCode: 56,
    index: 132,
    cnName: "智利",
    enName: "Chile",
    cnShortName: "Z"
  },
  {
    enShortName: "NA",
    areaCode: 264,
    index: 133,
    cnName: "那米比亚",
    enName: "Namibia",
    cnShortName: "N"
  },
  {
    enShortName: "YT",
    areaCode: 262,
    index: 134,
    cnName: "马约特",
    enName: "Mayotte",
    cnShortName: "M"
  },
  {
    enShortName: "RE",
    areaCode: 262,
    index: 135,
    cnName: "留尼汪岛",
    enName: "Reunion",
    cnShortName: "L"
  },
  {
    enShortName: "AO",
    areaCode: 244,
    index: 136,
    cnName: "安哥拉",
    enName: "Angola",
    cnShortName: "A"
  },
  {
    enShortName: "CH",
    areaCode: 41,
    index: 137,
    cnName: "瑞士",
    enName: "Switzerland",
    cnShortName: "R"
  },
  {
    enShortName: "BE",
    areaCode: 32,
    index: 138,
    cnName: "比利时",
    enName: "Belgium",
    cnShortName: "B"
  },
  {
    enShortName: "FJ",
    areaCode: 679,
    index: 139,
    cnName: "斐济",
    enName: "Fiji",
    cnShortName: "F"
  },
  {
    enShortName: "GH",
    areaCode: 233,
    index: 140,
    cnName: "加纳",
    enName: "Ghana",
    cnShortName: "J"
  },
  {
    enShortName: "MO",
    areaCode: 853,
    index: 141,
    cnName: "澳门（中国）",
    enName: "Macau",
    cnShortName: "A"
  },
  {
    enShortName: "MY",
    areaCode: 60,
    index: 142,
    cnName: "马来西亚",
    enName: "Malaysia",
    cnShortName: "M"
  },
  {
    enShortName: "MA",
    areaCode: 212,
    index: 143,
    cnName: "摩洛哥",
    enName: "Morocco",
    cnShortName: "M"
  },
  {
    enShortName: "TO",
    areaCode: 676,
    index: 144,
    cnName: "汤加",
    enName: "Tonga",
    cnShortName: "T"
  },
  {
    enShortName: "VU",
    areaCode: 678,
    index: 145,
    cnName: "瓦努阿图",
    enName: "Vanuatu",
    cnShortName: "W"
  },
  {
    enShortName: "VN",
    areaCode: 84,
    index: 146,
    cnName: "越南",
    enName: "Vietnam",
    cnShortName: "Y"
  },
  {
    enShortName: "JP",
    areaCode: 81,
    index: 147,
    cnName: "日本",
    enName: "Japan",
    cnShortName: "R"
  },
  {
    enShortName: "KM",
    areaCode: 269,
    index: 148,
    cnName: "科摩罗",
    enName: "Comoros",
    cnShortName: "K"
  },
  {
    enShortName: "CZ",
    areaCode: 420,
    index: 149,
    cnName: "捷克",
    enName: "Czech Republic",
    cnShortName: "J"
  },
  {
    enShortName: "GR",
    areaCode: 30,
    index: 150,
    cnName: "希腊",
    enName: "Greece",
    cnShortName: "X"
  },
  {
    enShortName: "IE",
    areaCode: 353,
    index: 151,
    cnName: "爱尔兰",
    enName: "Ireland",
    cnShortName: "A"
  },
  {
    enShortName: "IT",
    areaCode: 39,
    index: 152,
    cnName: "意大利",
    enName: "Italy",
    cnShortName: "Y"
  },
  {
    enShortName: "KW",
    areaCode: 965,
    index: 153,
    cnName: "科威特",
    enName: "Kuwait",
    cnShortName: "K"
  },
  {
    enShortName: "VA",
    areaCode: 39,
    index: 154,
    cnName: "梵蒂冈(罗马教廷)",
    enName: "Vatican City State (Holy See)",
    cnShortName: "F"
  },
  {
    enShortName: "BJ",
    areaCode: 229,
    index: 155,
    cnName: "贝宁",
    enName: "Benin",
    cnShortName: "B"
  },
  {
    enShortName: "CM",
    areaCode: 237,
    index: 156,
    cnName: "喀麦隆",
    enName: "Cameroon",
    cnShortName: "K"
  },
  {
    enShortName: "CI",
    areaCode: 225,
    index: 157,
    cnName: "Cote D'Ivoire",
    enName: "Cote D'Ivoire",
    cnShortName: "C"
  },
  {
    enShortName: "HR",
    areaCode: 385,
    index: 158,
    cnName: "克罗地亚",
    enName: "Croatia (local name: Hrvatska)",
    cnShortName: "K"
  },
  {
    enShortName: "EE",
    areaCode: 372,
    index: 159,
    cnName: "爱沙尼亚",
    enName: "Estonia",
    cnShortName: "A"
  },
  {
    enShortName: "GT",
    areaCode: 502,
    index: 160,
    cnName: "危地马拉",
    enName: "Guatemala",
    cnShortName: "W"
  },
  {
    enShortName: "GW",
    areaCode: 245,
    index: 161,
    cnName: "几内亚比绍",
    enName: "Guinea-Bissau",
    cnShortName: "J"
  },
  {
    enShortName: "FM",
    areaCode: 691,
    index: 162,
    cnName: "密克罗尼西亚",
    enName: "Micronesia",
    cnShortName: "M"
  },
  {
    enShortName: "WS",
    areaCode: 685,
    index: 163,
    cnName: "美属萨摩亚",
    enName: "Samoa",
    cnShortName: "M"
  },
  {
    enShortName: "SR",
    areaCode: 597,
    index: 164,
    cnName: "苏里南",
    enName: "Suriname",
    cnShortName: "S"
  },
  {
    enShortName: "EH",
    areaCode: 685,
    index: 165,
    cnName: "西撒哈拉",
    enName: "Western Sahara",
    cnShortName: "X"
  },
  {
    enShortName: "STP",
    areaCode: 239,
    index: 166,
    cnName: "圣多美和普林西比",
    enName: "Sao Tome and Principe",
    cnShortName: "S"
  },
  {
    enShortName: "SSD",
    areaCode: 211,
    index: 167,
    cnName: "南苏丹共和国",
    enName: "The Republic of South Sudan",
    cnShortName: "N"
  },
  {
    enShortName: "FI",
    areaCode: 358,
    index: 168,
    cnName: "芬兰",
    enName: "Finland",
    cnShortName: "F"
  },
  {
    enShortName: "CG",
    areaCode: 242,
    index: 169,
    cnName: "刚果",
    enName: "Congo",
    cnShortName: "G"
  },
  {
    enShortName: "HU",
    areaCode: 36,
    index: 170,
    cnName: "匈牙利",
    enName: "Hungary",
    cnShortName: "X"
  },
  {
    enShortName: "BD",
    areaCode: 880,
    index: 171,
    cnName: "孟加拉国",
    enName: "Bangladesh",
    cnShortName: "M"
  },
  {
    enShortName: "CY",
    areaCode: 357,
    index: 172,
    cnName: "塞浦路斯",
    enName: "Cyprus",
    cnShortName: "S"
  },
  {
    enShortName: "NF",
    areaCode: 6723,
    index: 173,
    cnName: "诺福克岛",
    enName: "Norfolk Island",
    cnShortName: "N"
  },
  {
    enShortName: "SN",
    areaCode: 221,
    index: 174,
    cnName: "塞内加尔",
    enName: "Senegal",
    cnShortName: "S"
  },
  {
    enShortName: "SE",
    areaCode: 46,
    index: 175,
    cnName: "瑞典",
    enName: "Sweden",
    cnShortName: "R"
  },
  {
    enShortName: "AS",
    areaCode: 684,
    index: 176,
    cnName: "萨摩亚",
    enName: "American Samoa",
    cnShortName: "S"
  },
  {
    enShortName: "FR",
    areaCode: 33,
    index: 177,
    cnName: "法国",
    enName: "France",
    cnShortName: "F"
  },
  {
    enShortName: "FX",
    areaCode: 33,
    index: 178,
    cnName: "法国大都会",
    enName: "France Metropolitan",
    cnShortName: "F"
  },
  {
    enShortName: "UZ",
    areaCode: 998,
    index: 179,
    cnName: "乌兹别克斯坦",
    enName: "Uzbekistan",
    cnShortName: "W"
  },
  {
    enShortName: "PF",
    areaCode: 689,
    index: 180,
    cnName: "法属玻里尼西亚",
    enName: "French Polynesia",
    cnShortName: "F"
  },
  {
    enShortName: "BW",
    areaCode: 267,
    index: 181,
    cnName: "博茨瓦纳",
    enName: "Botswana",
    cnShortName: "B"
  },
  {
    enShortName: "HT",
    areaCode: 509,
    index: 182,
    cnName: "海地",
    enName: "Haiti",
    cnShortName: "H"
  },
  {
    enShortName: "IQ",
    areaCode: 964,
    index: 183,
    cnName: "伊拉克",
    enName: "Iraq",
    cnShortName: "Y"
  },
  {
    enShortName: "NL",
    areaCode: 31,
    index: 184,
    cnName: "荷兰",
    enName: "Netherlands",
    cnShortName: "H"
  },
  {
    enShortName: "SM",
    areaCode: 378,
    index: 185,
    cnName: "圣马力诺共和国",
    enName: "San Marino",
    cnShortName: "S"
  },
  {
    enShortName: "BO",
    areaCode: 591,
    index: 186,
    cnName: "玻利维亚",
    enName: "Bolivia",
    cnShortName: "B"
  },
  {
    enShortName: "NC",
    areaCode: 687,
    index: 187,
    cnName: "新喀里多尼亚",
    enName: "New Caledonia",
    cnShortName: "X"
  },
  {
    enShortName: "MD",
    areaCode: 373,
    index: 188,
    cnName: "摩尔多瓦",
    enName: "Moldova",
    cnShortName: "M"
  },
  {
    enShortName: "NP",
    areaCode: 977,
    index: 189,
    cnName: "尼泊尔",
    enName: "Nepal",
    cnShortName: "N"
  },
  {
    enShortName: "TN",
    areaCode: 216,
    index: 190,
    cnName: "突尼斯",
    enName: "Tunisia",
    cnShortName: "T"
  },
  {
    enShortName: "AT",
    areaCode: 43,
    index: 191,
    cnName: "奥地利",
    enName: "Austria",
    cnShortName: "A"
  },
  {
    enShortName: "BG",
    areaCode: 359,
    index: 192,
    cnName: "保加利亚",
    enName: "Bulgaria",
    cnShortName: "B"
  },
  {
    enShortName: "SO",
    areaCode: 252,
    index: 193,
    cnName: "索马里",
    enName: "Somalia",
    cnShortName: "S"
  },
  {
    enShortName: "SY",
    areaCode: 963,
    index: 194,
    cnName: "叙利亚",
    enName: "Syrian Arab Republic",
    cnShortName: "X"
  },
  {
    enShortName: "TR",
    areaCode: 90,
    index: 195,
    cnName: "土耳其",
    enName: "Turkey",
    cnShortName: "T"
  },
  {
    enShortName: "DZ",
    areaCode: 213,
    index: 196,
    cnName: "阿尔及利亚",
    enName: "Algeria",
    cnShortName: "A"
  },
  {
    enShortName: "CV",
    areaCode: 238,
    index: 197,
    cnName: "佛得角",
    enName: "Cape Verde",
    cnShortName: "F"
  },
  {
    enShortName: "LR",
    areaCode: 231,
    index: 198,
    cnName: "利比里亚",
    enName: "Liberia",
    cnShortName: "L"
  },
  {
    enShortName: "MM",
    areaCode: 95,
    index: 199,
    cnName: "缅甸",
    enName: "Myanmar",
    cnShortName: "M"
  },
  {
    enShortName: "NZ",
    areaCode: 64,
    index: 200,
    cnName: "新西兰",
    enName: "New Zealand",
    cnShortName: "X"
  },
  {
    enShortName: "PN",
    areaCode: 64,
    index: 201,
    cnName: "皮特凯恩岛",
    enName: "Pitcairn",
    cnShortName: "P"
  },
  {
    enShortName: "ES",
    areaCode: 34,
    index: 202,
    cnName: "西班牙",
    enName: "Spain",
    cnShortName: "X"
  },
  {
    enShortName: "DJ",
    areaCode: 253,
    index: 203,
    cnName: "吉布提",
    enName: "Djibouti",
    cnShortName: "J"
  },
  {
    enShortName: "ZR",
    areaCode: 243,
    index: 204,
    cnName: "刚果民主共和国",
    enName: "Congo, The Democratic Republic Of The",
    cnShortName: "G"
  },
  {
    enShortName: "AF",
    areaCode: 93,
    index: 205,
    cnName: "阿富汗",
    enName: "Afghanistan",
    cnShortName: "A"
  },
  {
    enShortName: "BT",
    areaCode: 975,
    index: 206,
    cnName: "不丹",
    enName: "Bhutan",
    cnShortName: "B"
  },
  {
    enShortName: "IO",
    areaCode: 246,
    index: 207,
    cnName: "英属印度洋领地",
    enName: "British Indian Ocean Territory",
    cnShortName: "Y"
  },
  {
    enShortName: "BI",
    areaCode: 257,
    index: 208,
    cnName: "布隆迪",
    enName: "Burundi",
    cnShortName: "B"
  },
  {
    enShortName: "TP",
    areaCode: 670,
    index: 209,
    cnName: "东帝汶",
    enName: "East Timor",
    cnShortName: "D"
  },
  {
    enShortName: "GE",
    areaCode: 995,
    index: 210,
    cnName: "格鲁吉亚",
    enName: "Georgia",
    cnShortName: "G"
  },
  {
    enShortName: "GN",
    areaCode: 224,
    index: 211,
    cnName: "几内亚",
    enName: "Guinea",
    cnShortName: "J"
  },
  {
    enShortName: "IR",
    areaCode: 98,
    index: 212,
    cnName: "伊朗（伊斯兰共和国）",
    enName: "Iran (Islamic Republic of)",
    cnShortName: "Y"
  },
  {
    enShortName: "MR",
    areaCode: 222,
    index: 213,
    cnName: "毛里塔尼亚",
    enName: "Mauritania",
    cnShortName: "M"
  },
  {
    enShortName: "MU",
    areaCode: 230,
    index: 214,
    cnName: "毛里求斯",
    enName: "Mauritius",
    cnShortName: "M"
  },
  {
    enShortName: "RO",
    areaCode: 40,
    index: 215,
    cnName: "罗马尼亚",
    enName: "Romania",
    cnShortName: "L"
  },
  {
    enShortName: "SL",
    areaCode: 232,
    index: 216,
    cnName: "塞拉利昂",
    enName: "Sierra Leone",
    cnShortName: "S"
  },
  {
    enShortName: "SD",
    areaCode: 249,
    index: 217,
    cnName: "苏丹",
    enName: "Sudan",
    cnShortName: "S"
  },
  {
    enShortName: "TZ",
    areaCode: 255,
    index: 218,
    cnName: "坦桑尼亚",
    enName: "Tanzania",
    cnShortName: "T"
  },
  {
    enShortName: "AZ",
    areaCode: 994,
    index: 219,
    cnName: "阿塞拜疆",
    enName: "Azerbaijan",
    cnShortName: "A"
  },
  {
    enShortName: "NE",
    areaCode: 227,
    index: 220,
    cnName: "尼日尔",
    enName: "Niger",
    cnShortName: "N"
  },
  {
    enShortName: "EC",
    areaCode: 593,
    index: 221,
    cnName: "厄瓜多尔",
    enName: "Ecuador",
    cnShortName: "E"
  },
  {
    enShortName: "GF",
    areaCode: 594,
    index: 222,
    cnName: "法属圭亚那",
    enName: "French Guiana",
    cnShortName: "F"
  },
  {
    enShortName: "GP",
    areaCode: 590,
    index: 223,
    cnName: "瓜德罗普岛",
    enName: "Guadeloupe",
    cnShortName: "G"
  },
  {
    enShortName: "GU",
    areaCode: 1671,
    index: 224,
    cnName: "关岛",
    enName: "Guam",
    cnShortName: "G"
  },
  {
    enShortName: "MQ",
    areaCode: 596,
    index: 225,
    cnName: "马提尼克岛",
    enName: "Martinique",
    cnShortName: "M"
  },
  {
    enShortName: "SX",
    areaCode: 590,
    index: 226,
    cnName: "圣马丁岛",
    enName: "Saint Martin",
    cnShortName: "S"
  },
  {
    enShortName: "UG",
    areaCode: 256,
    index: 227,
    cnName: "乌干达",
    enName: "Uganda",
    cnShortName: "W"
  },
  {
    enShortName: "ER",
    areaCode: 291,
    index: 228,
    cnName: "厄立特里亚国",
    enName: "Eritrea",
    cnShortName: "E"
  },
  {
    enShortName: "MH",
    areaCode: 692,
    index: 229,
    cnName: "马绍尔群岛",
    enName: "Marshall Islands",
    cnShortName: "M"
  },
  {
    enShortName: "TK",
    areaCode: 690,
    index: 230,
    cnName: "托克劳",
    enName: "Tokelau",
    cnShortName: "T"
  },
  {
    enShortName: "TV",
    areaCode: 688,
    index: 231,
    cnName: "图瓦卢",
    enName: "Tuvalu",
    cnShortName: "T"
  },
  {
    enShortName: "WF",
    areaCode: 681,
    index: 232,
    cnName: "沃利斯和富图纳群岛",
    enName: "Wallis And Futuna Islands",
    cnShortName: "W"
  },
  {
    enShortName: "AQ",
    areaCode: 672,
    index: 233,
    cnName: "南极洲",
    enName: "Antarctica",
    cnShortName: "N"
  },
  {
    enShortName: "DO",
    areaCode: 1849,
    index: 234,
    cnName: "多米尼加共和国",
    enName: "Dominican Republic",
    cnShortName: "D"
  },
  {
    enShortName: "VG",
    areaCode: 1284,
    index: 235,
    cnName: "维尔京群岛(英国)",
    enName: "Virgin Islands (British)",
    cnShortName: "W"
  },
  {
    enShortName: "GG",
    areaCode: 441481,
    index: 236,
    cnName: "格恩西岛",
    enName: "Guernsey",
    cnShortName: "G"
  },
  {
    enShortName: "CN",
    areaCode: 86,
    index: 237,
    cnName: "中国",
    enName: "China",
    cnShortName: "Z"
  }
];

const formateList = () => {
  const obj = {};
  const state = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  for (let i = 0; i < state.length; i++) {
    const element = state[i];
    const codeSpell = codeList.filter(c => c.cnShortName.startsWith(element));
    if (codeSpell.length != 0) {
      obj[element] = codeSpell;
    }
  }
  return Object.entries(obj);
};
export default codeList;
