import http from "@helper/http";
import Track from "@helper/track";
import EventConfig from "./eventConfig";
/**
 * @description: 延迟注册
 * @param {type}
 * @return {type}
 */
export function delayRegister(params) {
  return http.post(
    `/operation-gateway/api/market-web-server/v1/registration/reg`,
    params
  );
}

/**
 * @description: 延迟注册后的更新学生信息
 * @param {type}
 * @return {type}
 */
export function completeInfo(params) {
  return http.post(
    `/operation-gateway/api/market-web-server/v1/registration/update/student`,
    params
  );
}

/**
 * @description: ；立即注册
 * @param {type}
 * @return {type}
 */
export function registerRightNow(params) {
  return http.post(
    `/operation-gateway/api/market-web-server/v2/registration/appointment`,
    params
  );
}
export function getGrade() {
  return http.post(
    "/operation-gateway/api/market-web-server/v1/dict/getDictGrade",
    {}
  );
}

const reportEvent = (eventName, params) => {
  try {
    let eventObj = {
      actKey: EventConfig.actKey,
      ...EventConfig[eventName]
    };
    Track.reportEventParse(eventObj, {
      pageName: EventConfig.pageName, // 带上活动名称
      ...params // 带上附加参数
    });
  } catch (error) {
    console.error("埋点报错啦，检查配置文件是否正确！", error);
  }
};

// 埋点处理下
export default reportEvent;
