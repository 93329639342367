export default {
  actKey: "", // 闭环数据埋点Key值
  pageName: "爱奇艺、抖音、微信等渠道H5页面承接", // 活动名称,同样也是百度埋点的第一个参数
  // 助力页确认
  enter_aqypc_page: {
    eventId: "enter_aqypc_page",
    isPage: true, // 如果是页面PV/UV就传 true
    params: {
      eventDesc: "首页曝光" // 若不为空，埋入百度
    }
  },
  click_pcrecxlk_button: {
    eventId: "click_pcrecxlk_button",
    params: {
      eventDesc: "领取心理课按钮点击" // 若不为空，埋入百度
    }
  },
  click_pcbuttomrec_button: {
    eventId: "click_pcbuttomrec_button",
    params: {
      eventDesc: "底部领取按钮" // 若不为空，埋入百度
    }
  },
  enter_pcwsxx_pop: {
    eventId: "enter_pcwsxx_pop",
    isPage: true, // 如果是页面PV/UV就传 true
    params: {
      eventDesc: "完善信息弹窗曝光" // 若不为空，埋入百度
    }
  },
  click_pcpoprec_button: {
    eventId: "click_pcpoprec_button",
    params: {
      eventDesc: "弹窗领取按钮点击" // 若不为空，埋入百度
    }
  },
  enter_pcscan_pop: {
    eventId: "enter_pcscan_pop",
    isPage: true, // 如果是页面PV/UV就传 true
    params: {
      eventDesc: "扫码承接页面曝光" // 若不为空，埋入百度
    }
  }
};
